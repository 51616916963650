import * as Types from '../graphql/types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CounterPartsFragment = (
  { __typename?: 'CounterType' }
  & Pick<Types.CounterType, 'code' | 'name' | 'limit' | 'total'>
);

export type UpdateCounterMutationVariables = Types.Exact<{
  code: Types.Scalars['String'];
  name: Types.Scalars['String'];
  limit: Types.Scalars['Int'];
}>;


export type UpdateCounterMutation = (
  { __typename?: 'Mutation' }
  & { updateCounter: (
    { __typename?: 'CounterUpdateMutation' }
    & Pick<Types.CounterUpdateMutation, 'ok'>
    & { counter?: Types.Maybe<(
      { __typename?: 'CounterType' }
      & CounterPartsFragment
    )> }
  ) }
);

export const CounterPartsFragmentDoc = gql`
    fragment CounterParts on CounterType {
  code
  name
  limit
  total
}
    `;
export const UpdateCounterDocument = gql`
    mutation UpdateCounter($code: String!, $name: String!, $limit: Int!) {
  updateCounter(code: $code, name: $name, limit: $limit) {
    ok
    counter {
      ...CounterParts
    }
  }
}
    ${CounterPartsFragmentDoc}`;
export type UpdateCounterMutationFn = Apollo.MutationFunction<UpdateCounterMutation, UpdateCounterMutationVariables>;

/**
 * __useUpdateCounterMutation__
 *
 * To run a mutation, you first call `useUpdateCounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCounterMutation, { data, loading, error }] = useUpdateCounterMutation({
 *   variables: {
 *      code: // value for 'code'
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUpdateCounterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCounterMutation, UpdateCounterMutationVariables>) {
        return Apollo.useMutation<UpdateCounterMutation, UpdateCounterMutationVariables>(UpdateCounterDocument, baseOptions);
      }
export type UpdateCounterMutationHookResult = ReturnType<typeof useUpdateCounterMutation>;
export type UpdateCounterMutationResult = Apollo.MutationResult<UpdateCounterMutation>;
export type UpdateCounterMutationOptions = Apollo.BaseMutationOptions<UpdateCounterMutation, UpdateCounterMutationVariables>;