import * as React from "react";
// import {PartialPartial} from "../utils";
import {
    CounterDocument,
    useCounterQuery,
    useStepCounterMutation,
} from "./counter.gen";
import { useFocus } from "../utils/use-focus";

export type InflightType = {
    clientId: number;
    step: number;
    state: "running" | "success" | "failed";
};

type InflightReducerActions = {
    add?: InflightType;
    remove?: number;
    success?: number;
    failed?: number;
};

const initialInflightMutations: InflightType[] = [];

const inflightReducer = (
    state: InflightType[],
    action: InflightReducerActions
) => {
    if (action.add) {
        return [action.add, ...state];
    }
    if (action.remove) {
        return state.filter((flying) => flying.clientId != action.remove);
    }
    if (action.success) {
        // const i = state.findIndex(
        //     (flying) => flying.clientId == action.success
        // );
        // state[i].state = "success";
        return state.map((fly) => {
            if (fly.clientId == action.success) {
                fly.state = "success";
            }
            return fly;
        });
    }
    if (action.failed) {
        // const i = state.findIndex(
        //     (flying) => flying.clientId == action.success
        // );
        // state[i].state = "success";
        return state.map((fly) => {
            if (fly.clientId == action.failed) {
                fly.state = "failed";
            }
            return fly;
        });
    }
    return state;
};

export interface CounterErrorUIProps {
    error: string;
}

export interface CounterLoadingUIProps {}

export interface CounterUIProps {
    counter: any;
    handleUpdate: (step: number) => void;
    inflight: InflightType[];
}

interface Props {
    code: string;
    Component: React.ComponentType<CounterUIProps>; //?
    LoadingComponent: React.ComponentType<CounterLoadingUIProps>; //?
}

export const CounterData: React.FC<Props> = (props) => {
    const { code, Component, LoadingComponent } = props;
    const focused = useFocus();

    //inflight queries
    const [state, dispatch] = React.useReducer(
        inflightReducer,
        initialInflightMutations
    );

    const { data, loading } = useCounterQuery({
        variables: { code: code },
        pollInterval: focused ? 5000 : 0,
        // skip: !focused,
    });
    const [updateCounter] = useStepCounterMutation({
        // onCompleted: (data) => {
        //     dispatch({ success: data.stepCounter.clientId });
        //     setTimeout(() => {
        //         dispatch({ remove: data.stepCounter.clientId });
        //     }, 1000);
        // },
    });

    const handleUpdate = (step: number) => {
        const clientId = new Date().getTime();
        updateCounter({
            variables: { code: code, step: step },
            // refetchQueries: [
            //     {
            //         query: CounterDocument,
            //         variables: { code: code },
            //     },
            // ],
            // awaitRefetchQueries: true,
        })
            .then((data) => {
                dispatch({ success: clientId });
            })
            .catch((reason) => {
                dispatch({ failed: clientId });
            })
            .finally(() => {
                setTimeout(() => {
                    dispatch({ remove: clientId });
                }, 3000);
            });
        dispatch({ add: { clientId, step, state: "running" } });
    };

    if (loading) {
        return <LoadingComponent />;
    }
    return (
        <Component
            counter={data!.counter}
            handleUpdate={handleUpdate}
            inflight={state}
        />
    );
};
