import * as React from "react";
import Container from "@material-ui/core/Container";
import { CssBaseline } from "@material-ui/core";

interface Props {}

export const LayoutView: React.FC<Props> = (props) => {
    return (
        <Container maxWidth="sm">
            <CssBaseline />
            {props.children}
        </Container>
    );
};
