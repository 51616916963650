import * as React from 'react'
import {CounterListLoadingUIProps} from "../counter-list-data";

interface Props extends CounterListLoadingUIProps {
}

export const CounterListLoadingUI: React.FC<Props> = props => {
    return (
        <div>Loading UI Component</div>
    )
};