import { ApolloClient, createHttpLink } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import * as Cookies from "js-cookie";

// const host = process.env.SITE_URL || 'localhost:8000';
const host =
    process.env.NODE_ENV === "production"
        ? "occupancy.hybridlogic.org"
        : // : "192.168.1.16:8000";
          "192.168.0.103:8000";
const ssl = process.env.NODE_ENV === "production" ? "s" : "";
const token = Cookies.get("csrftoken");

export const siteUrl = "http" + ssl + "://" + host + "/";

const httpLink = createHttpLink({
    uri: "http" + ssl + "://" + host + "/graphql", // todo: env?
    credentials: "include",
    headers: {
        "X-CSRFToken": token,
    },
});

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: httpLink,
});
