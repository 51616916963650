import * as React from 'react'
import {useCreateCounterMutation} from "./create-counter.gen";
import {useHistory} from "../router/router";

export interface CreateCounterUIProps {
    handleCreate: (name: string) => void
}

interface Props {
    Component: React.ComponentType<CreateCounterUIProps> //?
    // LoadingComponent: React.ComponentType<CounterListLoadingProps> //?
}

export const CreateCounterData: React.FC<Props> = props => {
    const {Component} = props;

    const history = useHistory();

    const [createCounter] = useCreateCounterMutation({
        onCompleted: data => {
            if (data.createCounter.ok) {
                history.replace(`/c/${data.createCounter.counter?.code}`)
            }
        }
    })
    const handleCreate = (name: string) => {
        createCounter({variables: {name}})
    }

    return (
        <Component handleCreate={handleCreate}/>
    )
};
