import * as Types from '../graphql/types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateCounterMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type CreateCounterMutation = (
  { __typename?: 'Mutation' }
  & { createCounter: (
    { __typename?: 'CounterCreateMutation' }
    & Pick<Types.CounterCreateMutation, 'ok'>
    & { counter?: Types.Maybe<(
      { __typename?: 'CounterType' }
      & Pick<Types.CounterType, 'code'>
    )> }
  ) }
);


export const CreateCounterDocument = gql`
    mutation CreateCounter($name: String!) {
  createCounter(name: $name) {
    ok
    counter {
      code
    }
  }
}
    `;
export type CreateCounterMutationFn = Apollo.MutationFunction<CreateCounterMutation, CreateCounterMutationVariables>;

/**
 * __useCreateCounterMutation__
 *
 * To run a mutation, you first call `useCreateCounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCounterMutation, { data, loading, error }] = useCreateCounterMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCounterMutation(baseOptions?: Apollo.MutationHookOptions<CreateCounterMutation, CreateCounterMutationVariables>) {
        return Apollo.useMutation<CreateCounterMutation, CreateCounterMutationVariables>(CreateCounterDocument, baseOptions);
      }
export type CreateCounterMutationHookResult = ReturnType<typeof useCreateCounterMutation>;
export type CreateCounterMutationResult = Apollo.MutationResult<CreateCounterMutation>;
export type CreateCounterMutationOptions = Apollo.BaseMutationOptions<CreateCounterMutation, CreateCounterMutationVariables>;