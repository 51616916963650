import * as React from 'react'
import {CreateCounterData} from "./create-counter-data";
import {CreateCounterUI} from "./create-counter-ui";

interface Props {
}

export const CreateCounterContainer: React.FC<Props> = props => {
    return (
        <CreateCounterData Component={CreateCounterUI}/>
    )
};