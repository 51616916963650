import * as Types from '../graphql/types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CounterListQueryVariables = Types.Exact<{
  codes: Array<Types.Scalars['String']>;
}>;


export type CounterListQuery = (
  { __typename?: 'Query' }
  & { counters: Array<(
    { __typename?: 'CounterType' }
    & Pick<Types.CounterType, 'id' | 'name' | 'code'>
  )> }
);


export const CounterListDocument = gql`
    query CounterList($codes: [String!]!) {
  counters(codes: $codes) {
    id
    name
    code
  }
}
    `;

/**
 * __useCounterListQuery__
 *
 * To run a query within a React component, call `useCounterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounterListQuery({
 *   variables: {
 *      codes: // value for 'codes'
 *   },
 * });
 */
export function useCounterListQuery(baseOptions?: Apollo.QueryHookOptions<CounterListQuery, CounterListQueryVariables>) {
        return Apollo.useQuery<CounterListQuery, CounterListQueryVariables>(CounterListDocument, baseOptions);
      }
export function useCounterListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CounterListQuery, CounterListQueryVariables>) {
          return Apollo.useLazyQuery<CounterListQuery, CounterListQueryVariables>(CounterListDocument, baseOptions);
        }
export type CounterListQueryHookResult = ReturnType<typeof useCounterListQuery>;
export type CounterListLazyQueryHookResult = ReturnType<typeof useCounterListLazyQuery>;
export type CounterListQueryResult = Apollo.QueryResult<CounterListQuery, CounterListQueryVariables>;