import * as React from "react";
import { CounterListContainer } from "components/counter-list";
import { CreateCounterFab } from "components/create-counter/create-counter-fab";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/AppBar";
import { Typography } from "@material-ui/core";
import { useCurrentUserQuery } from "components/graphql/queries/current-user.gen";

interface Props {}

export const MainView: React.FC<Props> = (props) => {
    const { data, loading } = useCurrentUserQuery();

    return (
        <>
            {/* <AppBar position={"static"}>
                <Toolbar>
                    <Typography variant="h6">Counters</Typography>
                    {data && data.user.email}
                </Toolbar>
            </AppBar> */}
            <CreateCounterFab />
            <CounterListContainer />
        </>
    );
};
