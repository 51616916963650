import * as React from 'react'
import Fab from '@material-ui/core/Fab'
import AddIcon from "@material-ui/icons/Add";
import { createStyles, Theme, useTheme, makeStyles} from "@material-ui/core/styles";
import {useHistory} from "../router/router";


interface Props {
}

export const CreateCounterFab: React.FC<Props> = props => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Fab className={classes.fab} color="primary" aria-label="add" onClick={() => history.push('/n/')}>
            <AddIcon/>
        </Fab>
    )
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
            width: 500,
            position: 'relative',
            minHeight: 200,
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
    }),
);