import * as React from "react";
import { Route, Switch } from "components/router/router";
import { CounterContainer } from "components/counter/counter-container";
import { CreateCounterContainer } from "components/create-counter";
import { EditCounterContainer } from "components/edit-counter";
import { MainView } from "views/main";

interface Props {}

export const RouterView: React.FC<Props> = (props) => {
    return (
        <Switch>
            <Route
                path={"/n/"}
                exact={true}
                component={CreateCounterContainer}
            />
            <Route
                path={"/c/:code"}
                exact={true}
                component={CounterContainer}
            />
            <Route
                path={"/c/:code/edit"}
                exact={true}
                component={EditCounterContainer}
            />
            <Route path={"/"} component={MainView} />
        </Switch>
    );
};
