import * as React from "react";

import { useCounterListQuery } from "./counter-list.gen";
import { PartialPartial } from "../utils";
import { CounterType } from "../graphql/types.gen";
import { CounterErrorUIProps } from "../counter/counter-data";

//force reload
export interface CounterListLoadingUIProps {}

export interface CounterListUIProps {
    counters: PartialPartial<CounterType, "count" | "limit" | "total">[];
}

interface Props {
    codes: string[];
    Component: React.ComponentType<CounterListUIProps>; //?
    LoadingComponent: React.ComponentType<CounterListLoadingUIProps>; //?
    ErrorComponent: React.ComponentType<CounterErrorUIProps>; //?
}

export const CounterListData: React.FC<Props> = (props) => {
    const { codes, Component, LoadingComponent, ErrorComponent } = props;

    const { loading, data, error } = useCounterListQuery({
        variables: { codes: codes },
        nextFetchPolicy: "cache-first",
    });
    if (loading && !data) {
        return <LoadingComponent />;
    }
    if (error) {
        return <ErrorComponent error={error.message} />;
    }
    return <Component counters={data!.counters} />;
};
