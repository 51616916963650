import * as React from 'react'
import {CounterListData} from "./counter-list-data";
import {CounterListUI} from "./counter-list-ui";
import {useStorage} from "../storage/storage";
import {CounterListErrorUI} from "./error-ui";
import {CounterListLoadingUI} from "./loading-ui";

interface Props {
}

export const CounterListContainer: React.FC<Props> = props => {
    const [counters] = useStorage<string[]>('counter-list', [])
    return (
        <CounterListData codes={counters}
                         Component={CounterListUI}
                         LoadingComponent={CounterListLoadingUI}
                         ErrorComponent={CounterListErrorUI}
        />
    )
};