import * as React from 'react'
import {
    Dialog, DialogActions, DialogTitle, makeStyles, Typography, Card, CardContent
} from '@material-ui/core'
import {siteUrl} from "../apollo";

const useStyles = makeStyles({});

interface Props {
    code: string
    open: boolean
    onClose: () => void
}

export const CounterDialog: React.FC<Props> = props => {
    const {code, open, onClose} = props;
    const classes = useStyles();

    const handleClose = () => {
        onClose();
    };

    //
    // const handleListItemClick = (value: string) => {
    //     onClose(value);
    // };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle>{code}</DialogTitle>
            <img style={{objectFit: 'contain'}} src={siteUrl + "qr/" + code + '.png'}/>
        </Dialog>
    )
};