import * as React from 'react'
// import {PartialPartial} from "../utils";
import {CounterDocument, useCounterQuery, useResetCounterMutation} from "../counter/counter.gen";
import {useUpdateCounterMutation} from "./edit-counter.gen";
import {useFocus} from "../utils/use-focus";
import {useHistory} from "../router/router";
import {useStorage} from "../storage/storage";

export interface EditCounterLoadingUIProps {
}

export interface EditCounterUIProps {
    counter: any
    updateCounter: (name: string, limit: number) => { success: boolean, msg: string }
    onReset: () => void  //todo: use mutation
    onRemove: () => void  //todo: use mutation
}

interface Props {
    code: string
    Component: React.ComponentType<EditCounterUIProps> //?
    // LoadingComponent: React.ComponentType<EditCounterLoadingUIProps> //?
}

export const EditCounterData: React.FC<Props> = props => {
    const {code, Component} = props
    const history = useHistory();
    const [counters, setCounters] = useStorage<string[]>('counter-list', [])


    const {data, loading} = useCounterQuery({
        variables: {code: code},
        // skip: !focused,
    })
    const [updateCounter] = useUpdateCounterMutation()
    const [resetCounter] = useResetCounterMutation()

    const handleUpdate = (name: string, limit: number) => {
        updateCounter({
            variables: {code: code, name: name, limit: limit},
            refetchQueries: [{
                query: CounterDocument,
                variables: {code: code}
            }]
        })
        return {success: true, msg: ''}
    }

    const handleReset = () => {
        resetCounter({
            variables: {code: code},
            refetchQueries: [{
                query: CounterDocument,
                variables: {code: code}
            }]
        }).then((data) => {
            history.replace(`/c/${code}/`)
        })
    }

    const handleRemove = () => {
        const index = counters.indexOf(code, 0);
        if (index > -1) {
            counters.splice(index, 1);
        }
        setCounters([...counters]);
        history.replace(`/`)
    };



    // if (loading) {
    //     return <LoadingComponent />
    // }
    return (
        <Component counter={data!.counter} updateCounter={handleUpdate} onReset={handleReset} onRemove={handleRemove}/>
    )
};