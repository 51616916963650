import * as React from 'react'
import {CounterUI} from "./counter-ui";
import {CounterData} from "./counter-data";
import {useParams} from "../router/router";
import {CounterLoadingUI} from "./counter-loading-ui";
import {useStorage} from "../storage/storage";

// import {useLocalStorage} from "../../utils/use-localstorage";

// type TParams = { code: string };

interface Props {
}

export const CounterContainer: React.FC<Props> = props => {
    const {code} = useParams()
    const [codes, setCodes] = useStorage<string[]>('counter-list', [])

    React.useEffect(() => {
        const index = codes.indexOf(code, 0);
        if (index > -1) {
            codes.splice(index, 1);
        }
        setCodes([code, ...codes])
    }, [code])

    return (
        <CounterData code={code}
                     Component={CounterUI}
                     LoadingComponent={CounterLoadingUI}/>
    )
};