import * as React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Fab from "@material-ui/core/Fab";
import { green, red } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "../router/router";

import { CounterUIProps, InflightType } from "./counter-data";
// import { CounterMenuUI } from "../counter-menu/counter-menu-ui";
import { useHistory } from "../router/router";
import { CounterMenuContainer } from "../counter-menu";
// import { DialPadUpUI, DialPadDownUI } from "./pad/pad-ui";
import Box from "@material-ui/core/Box";
import CircularProgress, {
    CircularProgressProps,
} from "@material-ui/core/CircularProgress";
// import { CounterGraphUI } from "../counter-graph/chart-graph";

interface Props extends CounterUIProps {}

export const CounterUI: React.FC<Props> = (props) => {
    const { counter, handleUpdate, inflight } = props;
    const classes = useStyles();

    const history = useHistory();

    return (
        <>
            <Card className={classes.card}>
                <CardHeader
                    title={
                        <>
                            <IconButton
                                component={Link}
                                to={"/"}
                                aria-label="back"
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                            {counter.name}
                        </>
                    }
                    action={<CounterMenuContainer code={counter.code} />}
                />
                <CardContent>
                    <Box
                        position="absolute"
                        display="flex"
                        flexDirection="column"
                        // className={classes.inflightBox}
                    >
                        {inflight.map((flying) => (
                            <InflightUI key={flying.clientId} flying={flying} />
                        ))}
                    </Box>
                    <Grid
                        container
                        direction="column"
                        justify="space-evenly"
                        alignItems="center"
                    >
                        <Grid item>
                            <Fab
                                className={classes.fab}
                                color="primary"
                                onClick={() => handleUpdate(1)}
                            >
                                <KeyboardArrowUpIcon fontSize={"large"} />
                            </Fab>
                        </Grid>
                        {/*<Grid item>*/}
                        {/*    <DialPadUpUI handleUpdate={handleUpdate} />*/}
                        {/*</Grid>*/}
                        <Grid item>
                            <Typography variant="h1" component="h2">
                                {counter.count}
                            </Typography>
                        </Grid>
                        {/*<Grid item>*/}
                        {/*    <DialPadDownUI handleUpdate={handleUpdate} />*/}
                        {/*</Grid>*/}
                        <Grid item>
                            <Fab
                                className={classes.fab}
                                color="primary"
                                onClick={() => handleUpdate(-1)}
                            >
                                <KeyboardArrowDownIcon fontSize={"large"} />
                            </Fab>
                        </Grid>
                        <Grid item className={classes.card}>
                            {counter.limit > 0 ? (
                                <Typography variant="h4" component="h2">
                                    Limit: {counter.limit}
                                </Typography>
                            ) : (
                                <></>
                            )}
                            <Typography variant="h4" component="h2">
                                Total: {counter.total}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {/*<CounterGraphUI code={props.counter.code} />*/}
        </>
    );
};

interface InflightProps extends CircularProgressProps {
    flying: InflightType;
    // clientId: number;
    // state: string;
    // step: number;
}

const InflightUI: React.FC<InflightProps> = (props) => {
    const { flying } = props;
    const classes = useStyles();

    function variant() {
        if (flying.state === "running") return "indeterminate";
        // if (flying.state === "success") return "static";
        return "static";
    }

    function boxClassName() {
        if (flying.state === "running") return classes.runningBox;
        if (flying.state === "success") return classes.successBox;
        if (flying.state === "failed") return classes.failedBox;
    }

    return (
        <Box
            className={boxClassName()}
            position="relative"
            display="inline-flex"
            marginTop={"4px"}
        >
            <CircularProgress
                className={classes[flying.state]}
                variant={variant()}
                value={100}
                {...props}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    // variant="caption"
                    component="div"
                    color="textSecondary"
                >
                    {flying.step > 0 && "+"}
                    {flying.step}
                </Typography>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles({
    card: {
        marginTop: "1rem",
    },
    fab: {
        width: "96px",
        height: "96px",
    },
    inflightBox: {
        display: "flex",
        position: "absolute",
        flexDirection: "column",
    },
    runningBox: {
        // transition: "opacity 3s ease-in-out",
        opacity: "1",
        transition: "opacity 2s ease-in-out",
    },
    successBox: {
        transition: "opacity 2s ease-in-out",
        // color: green[500],
        opacity: "0",
    },
    failedBox: {
        opacity: "0",
        transition: "opacity 2s ease-in-out",
    },
    running: {
        // transition: "opacity 3s ease-in-out",
        // opacity: "1",
    },
    success: {
        color: green[500],
        // opacity: "0",
    },
    failed: {
        color: red[500],
    },
});
