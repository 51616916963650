import * as React from 'react'
import {useParams} from "../router/router";
import {EditCounterUI} from "./edit-counter-ui";
import {EditCounterData} from "./edit-counter-data";

interface Props {
}

export const EditCounterContainer: React.FC<Props> = props => {
    const {code} = useParams()
    return (
        <EditCounterData code={code} Component={EditCounterUI} />
    )
};