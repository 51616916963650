import * as React from "react";
import { LayoutView } from "views/entrypoint/layout";
import { RouterView } from "views/entrypoint/routes";

interface Props {}

export const Entrypoint: React.FC<Props> = (props) => {
    return (
        <LayoutView>
            <RouterView />
        </LayoutView>
    );
};
