import * as React from 'react'
import {CounterDocument, useResetCounterMutation} from "../counter/counter.gen";
import {useStorage} from "../storage/storage";


export interface CounterMenuUIProps {
    code: string
    handleReset: () => void
    handleRemove: () => void
}

interface Props {
    Component: React.ComponentType<CounterMenuUIProps> //?
    code: string
}

export const CounterMenuData: React.FC<Props> = props => {
    const {Component, code} = props;
    const [resetCounter] = useResetCounterMutation()
    const [names, setNames] = useStorage<string[]>('counter-list', [])

    const handleReset = () => {
        resetCounter({
            variables: {code},
            refetchQueries: [{
                query: CounterDocument,
                variables: {code}
            }],
        });
    };
    const handleRemove = () => {
        const index = names.indexOf(code, 0);
        if (index > -1) {
            names.splice(index, 1);
        }
        setNames([...names]);
    };

    return (
        <Component handleReset={handleReset} handleRemove={handleRemove} code={code}/>
    )
};