import * as React from 'react'
import {CounterLoadingUIProps} from "./counter-data";
import {Text} from "native-base";

interface Props extends CounterLoadingUIProps {
}

export const CounterLoadingUI: React.FC<Props> = props => {
    return (
        <div>Loading UI Component</div>
    )
};