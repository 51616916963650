import React from "react";
import { ApolloProvider } from "@apollo/client";
import { client } from "components/apollo";
import { Router } from "components/router/router";
import { AppStateProvider } from "components/state";
import { Entrypoint } from "views/entrypoint";

export default function App() {
    return (
        <ApolloProvider client={client}>
            <AppStateProvider>
                <Router>
                    <Entrypoint />
                </Router>
            </AppStateProvider>
        </ApolloProvider>
    );
}
