import * as React from 'react'
import {EditCounterUIProps} from "./edit-counter-data";

interface Props extends EditCounterUIProps {
}

export const EditCounterUI: React.FC<Props> = props => {
    return (
        <></>
    )
};