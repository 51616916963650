import * as React from 'react'

type ReducerAttributeFilter = {
    type: 'toggleVibrate'
    vibrate: boolean
}

type ReducerAction = ReducerAttributeFilter // | ReducerMapCenter | ReducerActiveStoreType

type AppState = {
    vibrate: boolean
}

function InitReducer(state: AppState) {
    return state
}

const contextReducer = (state: AppState, action: ReducerAction) => {
    const updateState: Partial<AppState> = {};
    switch (action.type) {
        case 'toggleVibrate':
            updateState['vibrate'] = action.vibrate;
            break;
    }
    return {...state, ...updateState}
};

type MapContextType = {
    state: AppState
    dispatch: React.Dispatch<ReducerAction>
}

const DefaultAppContext: MapContextType = {
    state: {
        vibrate: true,
    },
    dispatch: () => {},
};


const AppContext = React.createContext(DefaultAppContext);

export const AppStateProvider: React.FC = ({children}) => {
    const [state, dispatch] = React.useReducer(contextReducer, DefaultAppContext.state, InitReducer);

    return (
        <AppContext.Provider value={{state: state, dispatch: dispatch}}>
            {children}
        </AppContext.Provider>
    )
};

export const useAppState = () => {
    const {state, dispatch} = React.useContext(AppContext);

    const toggleVibrate = (vibrate: boolean) => dispatch({
        type: 'toggleVibrate', vibrate
    });
    // const updateMap = (map: { center: Coords, zoom: number }) => dispatch({type: 'map', map: map});
    // const updateMapCenter = (map: { center: Coords }) => dispatch({type: 'map', map: map});
    // const updateMapZoom = (map: { zoom: number }) => dispatch({type: 'map', map: map});
    // const updateActiveStore = (activeStore: number) => dispatch({type: 'activeStore', activeStore: activeStore});


    return {state, toggleVibrate}
};