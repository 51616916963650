import * as React from 'react'
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {CreateCounterUIProps} from "./create-counter-data";

interface Props extends CreateCounterUIProps{
}

export const CreateCounterUI: React.FC<Props> = props => {
    const {handleCreate} = props;
    const classes = useStyles();
    const [name, setName] = React.useState('')

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.which === 13 && name !== '') {
            handleCreate(name);
        }
    }

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        handleCreate(name);
    }

    return (
        <Card className={classes.card}>
            <CardHeader title={"New CounterData"}/>
            <CardContent>
                <Grid container
                      spacing={1}
                      direction="row"
                      justify="center"
                      alignItems="center"
                >
                    <Grid item xs={9}>
                        <TextField value={name} onChange={handleChange} fullWidth={true} onKeyPress={handleKeyPress}/>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant={"contained"} color={'primary'}
                                disabled={name === ''}
                                fullWidth={true}
                                onClick={handleButtonClick}
                        >
                            Create
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};

const useStyles = makeStyles({
    card: {
        marginTop: '1rem',
    }
});