import * as React from 'react'
import {CounterErrorUIProps} from "../../counter/counter-data";

interface Props extends CounterErrorUIProps {
}

export const CounterListErrorUI: React.FC<Props> = props => {
    return (
        <div>{props.error}</div>
    )
};