import * as Types from '../graphql/types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CounterQueryVariables = Types.Exact<{
  code: Types.Scalars['String'];
}>;


export type CounterQuery = (
  { __typename?: 'Query' }
  & { counter: (
    { __typename?: 'CounterType' }
    & Pick<Types.CounterType, 'id' | 'name' | 'code' | 'count' | 'total' | 'limit'>
  ) }
);

export type StepCounterMutationVariables = Types.Exact<{
  code: Types.Scalars['String'];
  step: Types.Scalars['Int'];
}>;


export type StepCounterMutation = (
  { __typename?: 'Mutation' }
  & { stepCounter: (
    { __typename?: 'CounterStepMutation' }
    & Pick<Types.CounterStepMutation, 'ok'>
    & { counter?: Types.Maybe<(
      { __typename?: 'CounterType' }
      & Pick<Types.CounterType, 'id' | 'count' | 'total'>
    )> }
  ) }
);

export type ResetCounterMutationVariables = Types.Exact<{
  code: Types.Scalars['String'];
}>;


export type ResetCounterMutation = (
  { __typename?: 'Mutation' }
  & { resetCounter: (
    { __typename?: 'CounterResetMutation' }
    & Pick<Types.CounterResetMutation, 'ok'>
  ) }
);


export const CounterDocument = gql`
    query Counter($code: String!) {
  counter(code: $code) {
    id
    name
    code
    count
    total
    limit
  }
}
    `;

/**
 * __useCounterQuery__
 *
 * To run a query within a React component, call `useCounterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounterQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCounterQuery(baseOptions?: Apollo.QueryHookOptions<CounterQuery, CounterQueryVariables>) {
        return Apollo.useQuery<CounterQuery, CounterQueryVariables>(CounterDocument, baseOptions);
      }
export function useCounterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CounterQuery, CounterQueryVariables>) {
          return Apollo.useLazyQuery<CounterQuery, CounterQueryVariables>(CounterDocument, baseOptions);
        }
export type CounterQueryHookResult = ReturnType<typeof useCounterQuery>;
export type CounterLazyQueryHookResult = ReturnType<typeof useCounterLazyQuery>;
export type CounterQueryResult = Apollo.QueryResult<CounterQuery, CounterQueryVariables>;
export const StepCounterDocument = gql`
    mutation StepCounter($code: String!, $step: Int!) {
  stepCounter(code: $code, step: $step) {
    ok
    counter {
      id
      count
      total
    }
  }
}
    `;
export type StepCounterMutationFn = Apollo.MutationFunction<StepCounterMutation, StepCounterMutationVariables>;

/**
 * __useStepCounterMutation__
 *
 * To run a mutation, you first call `useStepCounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStepCounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stepCounterMutation, { data, loading, error }] = useStepCounterMutation({
 *   variables: {
 *      code: // value for 'code'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useStepCounterMutation(baseOptions?: Apollo.MutationHookOptions<StepCounterMutation, StepCounterMutationVariables>) {
        return Apollo.useMutation<StepCounterMutation, StepCounterMutationVariables>(StepCounterDocument, baseOptions);
      }
export type StepCounterMutationHookResult = ReturnType<typeof useStepCounterMutation>;
export type StepCounterMutationResult = Apollo.MutationResult<StepCounterMutation>;
export type StepCounterMutationOptions = Apollo.BaseMutationOptions<StepCounterMutation, StepCounterMutationVariables>;
export const ResetCounterDocument = gql`
    mutation ResetCounter($code: String!) {
  resetCounter(code: $code) {
    ok
  }
}
    `;
export type ResetCounterMutationFn = Apollo.MutationFunction<ResetCounterMutation, ResetCounterMutationVariables>;

/**
 * __useResetCounterMutation__
 *
 * To run a mutation, you first call `useResetCounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCounterMutation, { data, loading, error }] = useResetCounterMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useResetCounterMutation(baseOptions?: Apollo.MutationHookOptions<ResetCounterMutation, ResetCounterMutationVariables>) {
        return Apollo.useMutation<ResetCounterMutation, ResetCounterMutationVariables>(ResetCounterDocument, baseOptions);
      }
export type ResetCounterMutationHookResult = ReturnType<typeof useResetCounterMutation>;
export type ResetCounterMutationResult = Apollo.MutationResult<ResetCounterMutation>;
export type ResetCounterMutationOptions = Apollo.BaseMutationOptions<ResetCounterMutation, ResetCounterMutationVariables>;