import * as React from 'react'
import {IconButton, Menu, MenuItem, Divider} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {CounterDocument, useResetCounterMutation} from "../counter/counter.gen";
import {useStorage} from '../storage/storage';
import {Link} from "react-router-dom";
import {CounterDialog} from "./counter-dialog";
import {CounterMenuUIProps} from "./counter-menu-data";


interface Props extends CounterMenuUIProps{
}

export const CounterMenuUI: React.FC<Props> = props => {
    const {code} = props;

    const [open, setOpen] = React.useState(false);

    const onClose = () => {
        setOpen(false);
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleReset = () => {
        setAnchorEl(null);
        props.handleReset();
    };
    const handleRemove = () => {
        setAnchorEl(null);
        props.handleRemove();
    };

    const handleShare = () => {
        setAnchorEl(null);
        setOpen(true);
    }

    return (
        <>
            <IconButton aria-label="menu" onClick={handleClick}>
                <MoreVertIcon/>
            </IconButton>
            <Menu id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
            >
                <MenuItem onClick={handleReset}>Reset</MenuItem>
                <MenuItem onClick={handleShare}>Share</MenuItem>
                <Divider/>
                <MenuItem component={Link} to={"/"} onClick={handleRemove}>Remove</MenuItem>
            </Menu>
            <CounterDialog code={code} open={open} onClose={onClose}/>
        </>
    )
};