import * as React from 'react'
import {CounterMenuData} from "./counter-menu-data";
import {CounterMenuUI} from "./counter-menu-ui";

interface Props {
    code: string
}

export const CounterMenuContainer: React.FC<Props> = props => {
    return (
        <CounterMenuData code={props.code} Component={CounterMenuUI}/>
    )
};