import * as React from 'react'

export const useFocus = () => {
    const [focused, setFocused] = React.useState(document.hasFocus());

    React.useEffect(()=> {
        const handleFocus = () => setFocused(true);
        const handleBlur = () => setFocused(false);

        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        }
    },[]);

    return focused;
}