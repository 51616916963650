import * as React from "react";
import { CounterListUIProps } from "./counter-list-data";
import ListItem from "@material-ui/core/ListItem";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import AppBar from "@material-ui/core/AppBar";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "../router/router";
import { siteUrl } from "../apollo";
import { maybeAndroid } from "../utils/user-agent";

interface Props extends CounterListUIProps {}

export const CounterListUI: React.FC<Props> = (props) => {
    const { counters } = props;
    const classes = useStyles();

    return (
        <>
            <Card className={classes.card}>
                <CardHeader title={"Recent"} />
                <CardContent>
                    <List component="nav" aria-label="main mailbox folders">
                        {counters.map((counter) => {
                            return (
                                <ListItem
                                    key={counter.code}
                                    button
                                    component={Link}
                                    to={`/c/${counter.code}`}
                                >
                                    <ListItemText primary={counter.name} />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            component={Link}
                                            to={`/c/${counter.code}`}
                                        >
                                            <ChevronRightIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                        {counters.length === 0 ? (
                            <ListItem key={"__nothing"}>Nothing here</ListItem>
                        ) : (
                            <></>
                        )}
                    </List>
                </CardContent>
            </Card>

            {maybeAndroid() && (
                <div className={classes.badgeContainer}>
                    <a
                        href={
                            "https://play.google.com/store/apps/details?id=org.hybridlogic.occupancy"
                        }
                    >
                        <img
                            className={classes.badge}
                            src={siteUrl + "static/google-play-badge.png"}
                        />
                    </a>
                </div>
            )}
        </>
    );
};

const useStyles = makeStyles({
    card: {
        marginTop: "1rem",
    },
    badgeContainer: {
        position: "fixed",
        bottom: 0,
        left: 0,
    },
    badge: {
        width: "182px",
    },
});
